import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CallToAction from "../components/call-to-action"
import { CareersMeta } from "../data/seo-meta"

import heartInHand from "../images/careers/heart_in_hand.svg"
import space from "../images/careers/space.svg"
import quoteIcon from "../images/careers/quote.svg"
import pingPong from "../images/careers/ping_pong.svg"
import Badge from "../components/badge"

const whoWeAre = [
  {
    color: "#D91451",
    title: "Visionary",
    content:
      "Questioning, disrupting and defying assumptions, we are big-picture thinkers with insightful ideas, poised to take advantage of never-before-possible opportunities.",
  },
  {
    color: "#B81144",
    title: "Community",
    content:
      "Fair-minded, open-minded, and inclusive, we’re here for small businesses, our client partners, and our internal teams. We want success for all!",
  },
  {
    color: "#980E38",
    title: "Dynamic",
    content:
      "We work hard, we give our best, and we love it. We are eyes-wide-open constantly learning, adapting, and responding to the changing world around us.",
  },
  {
    color: "#89012A",
    title: "Engaging",
    content:
      "We’re excited about what we’re building and we invite everyone along on the journey. It’s fun to share our passion with other sunny-side-up allies.",
  },
]

const quotes = [
  "The entire team is friendly, approachable, engaged, and willing to share their knowledge and ideas.",
  "The people are brilliant, humble, and kind. The work is global, challenging, and interesting. It’s unlike any other job I have had, in all the right ways.",
  "It’s fun to be a part of a team working with the newest technologies – learning and building in an agile development approach. It’s fast-paced and challenging, but so rewarding.",
]

const values = [
  {
    title: "Fearless Innovation",
    badges: ["New technologies", "Data Science", "Re-imagining", "Disruption"],
  },
  {
    title: "Boundless Opportunity",
    badges: [
      "All sizes",
      "Economics",
      "Motivation",
      "Goal-achieving",
      "Future-thinking",
    ],
  },
  {
    title: "Prosperity for All",
    badges: [
      "Equality",
      "Profitability",
      "Security",
      "Business-generating",
      "Big-dreaming",
    ],
  },
  {
    title: "Cultivating Relationships",
    badges: [
      "Collaboration",
      "Kindness",
      "Welcoming",
      "Multi-perspectives",
      "Enduring",
    ],
  },
]

const CareersPage = () => {
  return (
    <Layout>
      <SEO
        description={CareersMeta.description}
        path={CareersMeta.path}
        title={CareersMeta.title}
      />
      <div className="container py-20 md:py-24">
        <div className="grid md:grid-cols-2 gap-20 mb-32">
          <div className="md:pr-28">
            <h1 className="h1 mb-8">Join Us</h1>
            <p className="p1">
              We’re on a mission to build a team of collaborative, open-minded,
              self-starters ready to shake up all things insurance.
              <br />
              <br />
              <CallToAction
                label="View current openings"
                to="https://slicelabs.bamboohr.com/jobs/"
                external={true}
              />
            </p>
          </div>
          <img
            className="mx-auto md:mx-0"
            src={heartInHand}
            alt="Woman holding a heart in her hand"
          />
        </div>

        <h2 className="h2 mb-4">Who We Are</h2>
        <p className="p1 mb-8">And what we do. And how we do it.</p>
        <div className="grid md:grid-cols-4 mb-40">
          {whoWeAre.map((point) => (
            <div
              className="px-4 py-8"
              key={point.title}
              style={{ borderTop: `4px solid ${point.color}` }}
            >
              <h3 className="h3 mb-4">{point.title}</h3>
              <p className="p2 md:pr-12">{point.content}</p>
            </div>
          ))}
        </div>

        <div className="grid md:grid-cols-2 mb-40">
          <img className="mt-28 mx-auto" src={space} alt="Astronaut in space" />
          <div>
            <h2 className="h2 mb-4">Our Values</h2>
            <p className="p1 mb-8">This is what makes us, us.</p>
            {values.map((value) => (
              <div className="mb-16" key={value.title}>
                <h3 className="p1 md:text-[2.4rem] text-[#b81144] mb-8">
                  {value.title}
                </h3>
                <ul className="flex flex-wrap max-w-[40rem]">
                  {value.badges.map((badge) => (
                    <li className="mr-4 mb-4" key={badge}>
                      <Badge label={badge} />
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>

        <h2 className="h2 mb-4">What the people are saying.</h2>
        <p className="p1 mb-8">
          Chatter from those who know. <em>(Because they work here.)</em>
        </p>
        <div className="grid gap-8 md:grid-cols-3 mb-40">
          {quotes.map((quote) => (
            <div
              className="-mx-8 px-8 py-16 bg-[#f8f8f8] md:mx-0 rounded-2xl drop-shadow"
              key={quote}
            >
              <img className="mb-8" src={quoteIcon} alt="Quote icon" />
              <p className="p1 mb-4">{quote}</p>
            </div>
          ))}
        </div>

        <div className="md:text-center mb-12">
          <img
            className="mx-auto"
            src={pingPong}
            alt="People playing ping pong"
          />
          <h2 className="h1 my-8 mx-auto max-w-[84rem]">
            Ready for an Opportunity?
          </h2>
          <p className="p1">
            Join our team of award-winning innovators.
            <br />
            <br />
            <CallToAction
              label="View current openings"
              to="https://slicelabs.bamboohr.com/jobs/"
              external={true}
            />
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default CareersPage
